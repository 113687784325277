import React, { useState } from 'react';
import styled from 'styled-components';
import Deemby from './imagenes/deembyDes.png';
import Deemby2 from './imagenes/logoDeemby.png';
import { useNavigate } from 'react-router-dom'; 
import { InputGroupText, FormGroup, Input, InputGroup, Button } from 'reactstrap';

var md5 = require('md5');

const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  color:#0D6EFD;
`;


const Logo = styled.img`
  width: 40%; 
  height: auto;
  margin-bottom: 20%;
`;


const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const Formulario = ({isDesktop}) => {
  const navigate = useNavigate(); 
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({ avatar: '', pass: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { avatar, pass } = formData;
  
    if (!avatar || !pass) {
      setError('Por favor completa todos los campos.');
      return;
    }
  
    const hashedPass = md5(pass);
  
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ avatar, pass: hashedPass, type: 'Login' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('id_user', data.usuario_id);
        navigate('/');
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };
  
  return (
    <FormContainer>
      <Logo src={Deemby} alt="Logo" />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <InputGroupText>
            @
          </InputGroupText>
          <Input 
                type="text" 
                name="avatar" 
                className="form-control" 
                placeholder="usuari@" 
                value={formData.avatar} 
                onChange={handleChange} 
              />
        </InputGroup><br/><br/>
        <FormGroup>
          <Input 
            type="password" 
            name="pass"  
            placeholder="Contraseña" 
            className="form-control"  
            value={formData.pass} 
            onChange={handleChange} 
          />
        </FormGroup>
        <FormGroup style={{textAlign:'center'}}>
          <Button type="submit" color='primary'>Iniciar Sesión</Button>
        </FormGroup>
      </form>
      <p onClick={() => navigate('/RecovePass')} style={{textAlign:'center'}}>Recuperar Contraseña</p>
    </FormContainer>
  );
};

export default Formulario;
