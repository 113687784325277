import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import TopBar from './TopBarUser';

function FormSeguridad() {
  const id_user = localStorage.getItem('id_user');
  const [showModal, setShowModal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [notificaciones, setNotificaciones] = useState([]);
  const [viewNotificaciones, setViewNotificaciones] = useState([]);
  const [numNotificaciones, setNumNotificaciones] = useState(0);
  
  const handleModalClose = () => setShowModal(false);
  const handleCollapseToggle = () => setCollapseOpen(!collapseOpen);

  const arSoli = async (status) => {
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id_soli:viewNotificaciones.organizacion_solicitud_id,status,type: 'ResponseRequest'}),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
  
      const data = await response.json();
      if (data.success) {
        getNotification();
        Swal.fire({
          title: "Deemby",
          text: data.message,
          icon: "success"
        });
      }else{
        Swal.fire({
          title: "Deemby",
          text: data.message,
          icon: "error"
        });
      }

    } catch (error) {
      console.error('Error:', error);
    }
  }
  const showNotification = (noti) => {
    setShowModal(true);
    setViewNotificaciones(noti);
  }

  const getNotification = async () => {
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_user,type: 'GetNotification' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
  
      const data = await response.json();
      if (data.success) {
        setNotificaciones(data.data);
        setNumNotificaciones(data.data.length);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className="container">
      <TopBar />
      <div>
        <h2 onClick={handleCollapseToggle} style={{ cursor: 'pointer', position: 'relative' }}>
          Notificaciones 
          <span style={{ 
            backgroundColor: 'red', 
            color: 'white', 
            borderRadius: '50%', 
            padding: '10px', 
            fontSize: '10px',
            position: 'absolute',
            marginLeft:'2%'
          }}>
            {numNotificaciones}
          </span>
        </h2>
        {notificaciones.map((notificacion, index) => (
          <div style={{ display: collapseOpen ? 'block' : 'none',paddingLeft:'3%' }}>
            <h5 style={{ cursor: 'pointer' }} onClick={() => showNotification(notificacion)}>{notificacion.sistema}</h5>
          </div>
        ))}
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={handleModalClose}
        contentLabel="Datos Personales"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            padding: '30px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center'
          }
        }}
      >
        <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>{viewNotificaciones.sistema}</h1>
        <p style={{ marginBottom: '20px' }}>{viewNotificaciones.nick} te ha enviado esta solicitud para que te unas a su equipo en {viewNotificaciones.sistema}</p>
        <div>
          <button className='btn btn-primary' style={{ marginRight: '10px' }} onClick={() => arSoli(2)}>Aceptar</button>
          <button className='btn btn-danger' onClick={() => arSoli(3)}>Rechazar</button>
        </div>
      </Modal>

    </div>
  );
}

export default FormSeguridad;
