import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';
import Swal from 'sweetalert2'

function FormIntegraciones() {
  const id_user = localStorage.getItem('id_user');
  const url = 'https://novomediaapp.azurewebsites.net/assents/user/';
  const defaultImageUrl = 'https://cdn-icons-png.freepik.com/512/468/468833.png';
  const [colaboradores, setColaboradores] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [integraciones, setIntegraciones] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [formType, setFormType] = useState(''); 
  const [correo, setCorreo] = useState(''); 
  const [isPending, setIsPending] = useState(false); 
  const [statusSoli, setstatusSoli] = useState(-1); 
  const [organizacion, setOrganizacion] = useState(0); 
  const [colaboradoresSolicitud, setcolaboradoresSolicitud] = useState([]); 
  const [semblanza, setSemblanza] = useState('');
  const [experiencia, setExperiencia] = useState('');

  const handleModalClose = () => setShowModal(false);
  const handleCollapseToggle = () => setCollapseOpen(!collapseOpen);
  
  useEffect(() => {
    getRequests();
  }, [id_user]);

  const getColabs = (org = organizacion) => {
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({organizacion:org,type:'GetColabs'}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setcolaboradoresSolicitud(data.data);
      } else {
        console.error('Error al obtener los colaboradores:', data.message);
      }
    })
    .catch(error => console.error('Error de red:', error));
  }

  const getRequests = () => {
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id_user,type:'GetIntegraciones'}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setIntegraciones(data.data);
      } else {
        console.error('Error al obtener las integraciones:', data.message);
      }
    })
    .catch(error => console.error('Error de red:', error));
  }

  const handleCorreo = (event) => {
    setCorreo(event.target.value);
  };  

  const handleFormOpen = (type) => {
    const pending = Array.isArray(integraciones) && integraciones.some(integracion => integracion.id_sistema === type);
    const index = Array.isArray(integraciones) ? integraciones.findIndex(integracion => integracion.id_sistema === type) : -1;
    var integra=-1;
    var organiza=0;
    
    
    if(index>=0){
      integra=integraciones[index]['status'];
      organiza=integraciones[index]['id_organizacion'];
    }

    setOrganizacion(organiza);
    setstatusSoli(integra);
    setIsPending(pending);
    setFormType(type);
    setShowModal(true);
    getColabs(organiza);
    
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var data = {};
    if (formType === '4') {
      // Aquí enviarías los datos al backend
      data = {
        semblanza: semblanza,
        logros: experiencia
      };
      
      // Aquí puedes agregar tu lógica para enviar los datos al backend utilizando fetch, axios u otra librería
    }

    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id_user,type:'SaveRequests',formType,correo,data}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setIntegraciones(data.secciones);
      } else {
        console.error('Error al obtener las secciones:', data.message);
      }
    })
    .catch(error => console.error('Error de red:', error));
    setCorreo('');
    setShowModal(false);
    getRequests();
  };

  const handleColaboradorChange = (event) => {
    setColaboradores(event.target.value);
  };
  
  const handleSubmitColaboradores = (event) => {
    event.preventDefault();
    const existe = colaboradoresSolicitud.some(colaborador => colaborador.nick === colaboradores);

    // Si el valor ya existe, mostrar un mensaje de error
    if (existe) {
      Swal.fire({
        title: "Deemby",
        text: 'El usuario ya esta en la lista de tus colaboradores.',
        icon: "error"
      });
    } else {
      fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
        body: JSON.stringify({organizacion,type:'SaveRequestsColabs',colaboradores,id_user}),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setColaboradores('');
          getRequests();
          getColabs();
          Swal.fire({
            title: "Deemby",
            text: data.message,
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "Deemby",
            text: data.message,
            icon: "error"
          });
        }
      }).catch(error => console.error('Error de red:', error));
    }
  };
  

  return (
    <div className="container">
      <div>
        <h4 onClick={handleCollapseToggle} style={{ cursor: 'pointer', paddingBottom: '2%' }}>Integraciones</h4>
        <div style={{ display: collapseOpen ? 'block' : 'none', paddingLeft: '3%', paddingBottom: '2%' }}>
          <h5 style={{ cursor: 'pointer' }} onClick={() => handleFormOpen('10')}>Info Candidato</h5>
        </div>
        <div style={{ display: collapseOpen ? 'block' : 'none', paddingLeft: '3%', paddingBottom: '2%' }}>
          <h5 style={{ cursor: 'pointer' }} onClick={() => handleFormOpen('4')}>Info Gobierno</h5>
        </div>
        <div style={{ display: collapseOpen ? 'block' : 'none', paddingLeft: '3%', paddingBottom: '2%' }}>
          <h5 style={{ cursor: 'pointer' }} onClick={() => handleFormOpen('12')}>Punto Libre</h5>
        </div>
      </div>

      {/* Modal para datos personales */}
      <Modal
        isOpen={showModal}
        onRequestClose={handleModalClose}
        contentLabel="Datos Personales"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
          },
          content: {
            top: '15%',
            left: '5%',
            right: '5%',
            bottom: 'auto',
            width: '90%'
          }
        }}
      >
        <h2>{formType === '10' ? 'Info Candidato' : formType === '4' ? 'Info Gobierno' : 'Punto Libre'}</h2>
        {isPending && statusSoli==='1'?
        <h1>Tienes una solicitud pendiente en 24 horas te responderemos.</h1>
        :isPending && statusSoli==='2'?
        <div>
          <h4>Integra a tus colaboradores.</h4>
          <form onSubmit={handleSubmitColaboradores}>
            <div className="form-group">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  className="form-control"
                  value={colaboradores}
                  onChange={handleColaboradorChange}
                  placeholder="Ingresa usuari@"
                  aria-describedby="basic-addon1"
                />
              </div><br/>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Colaborador</th>
                    <th>Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {colaboradoresSolicitud.map((colab, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={url + colab.usuario_id+'.jpg'}
                          onError={(e) => { e.target.src = defaultImageUrl; }}
                          style={{ width: 100, height: 100 }}
                          className="img-fluid rounded-circle" // Estilos de Bootstrap para imágenes redondeadas y fluidas
                          alt={`Imagen de usuario ${colab.usuario_id}`}
                        />
                      </td>
                      <td>{colab.nick}</td>
                      <td>{colab.estatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
        :
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="email" className="form-control" id="correo" 
            value={correo}
            onChange={handleCorreo}
            placeholder={formType === '10' ? 'Ingrese el correo institucional' 
            : formType === '4' 
            ? 'Ingrese el correo institucional' 
            : 'Ingrese el correo de la casa editora'} /><br/>
          </div>
          {formType === '4' && (
            <>
              <div className="form-group">
                <textarea id="semblanza" className="form-control" name="semblanza" 
                value={semblanza} onChange={(e) => setSemblanza(e.target.value)} placeholder='Semblanza'/>
                <br/>
              </div>
              <div className="form-group">
                <textarea id="experiencia" className="form-control" name="experiencia" 
                value={experiencia} onChange={(e) => setExperiencia(e.target.value)} placeholder='Logros'/>
                <br/>
              </div>
            </>
            
          )}
          <button type="submit" className="btn btn-primary">Siguiente</button>
        </form>}
        
      </Modal>
    </div>
  );
}

export default FormIntegraciones;
