import React from 'react';
import TopBar from './TopBar';
import { useNavigate } from 'react-router-dom'; 
import styled from 'styled-components';

const Button = styled.button`
  padding: 15px 40px; /* Ajustamos el padding para hacer los botones más alargados */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px; /* Agregamos margen inferior para separar los botones */
`;

const BlueButton = styled(Button)`
  background-color: #5c7bfa;
  color: white;
`;

const Welcome = () => {
  const navigate = useNavigate(); 

  return (
    <div style={{textAlign:'center',padding:'15%'}}>
      <TopBar/>
      <p>Bienvenido a la colmena
          No olvides registrar una app de
          autenticación doble como Google
          Authenticator o Microsoft
          Authenticator, ambas gratuitas, la
          necesitarás si tu número de
          Whatsapp cambia o si tendrás rol
          como creador de contenido en
          cualquiera de las integraciones
          disponibles.
      </p>
      <BlueButton onClick={() => navigate('/Login')}>Iniciar Sesión</BlueButton>
    </div>
  );
};

export default Welcome;
