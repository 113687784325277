import React, { useEffect,useState } from 'react';
import TopBar from './TopBarUser';
import 'bootstrap/dist/css/bootstrap.min.css';
import Perfil from './FormPerfil';
import Seguridad from './FormSeguridad';
import Integraciones from './FormIntegraciones';


const FormExtraData = ({isDesktop}) => {
 
  return (
    <div>
      <TopBar isDesktop={isDesktop}/>
      <Perfil isDesktop={isDesktop}/>
      <Seguridad/>
      <Integraciones/>
      <br/><br/><br/><br/>
    </div>
  );
};

export default FormExtraData;
