import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import styled from 'styled-components';
import { InputGroupText, Input, InputGroup } from 'reactstrap';
import { FaInfoCircle} from 'react-icons/fa';
import Swal from 'sweetalert2'

const styles = {
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px', // Agregamos un margen inferior para separar las filas
  },
  inputGroupPrepend: {
    display: 'flex', // Agregado
    alignItems: 'center', // Agregado
    backgroundColor: '#e9ecef',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    paddingLeft: '10px', // Agregado
    paddingRight: '10px', // Agregado
  },
  inputGroupText: {
    color: 'black',
    fontSize: '14px'
  },
  input: {
    padding: '10px 20px',
    margin: '5px',
    width: '100%',
    border: '1px solid #5c7cfa',
    borderRadius: '5px',
    fontSize: '14px'
  },
  select: {
    padding: '10px 20px',
    margin: '4px',
    width: '100%',
    border: '1px solid #5c7cfa',
    borderRadius: '5px',
    fontSize: '14px'
  },
  button: {
    padding: '10px 20px',
    margin: '5px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
};

function FormPerfil({isDesktop}) {
  const id_user = localStorage.getItem('id_user');
  const [image, setImage] = useState('https://novomediaapp.azurewebsites.net/assents/user/'+id_user+'.jpg');
  const [showModal, setShowModal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [error, setError] = useState('');
  const [datosPersonales, setDatosPersonales] = useState([]);
  const [generos, setGeneros] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [formData, setFormData] = useState({});
  const [escolaridades, setEscolaridades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [validaSeccion, setValidaSeccion] = useState(false);
  const [valor, setValor] = useState(0);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  const handleCollapseToggle = () => setCollapseOpen(!collapseOpen);

  const ErrorMessage = styled.p`
    color: red;
    font-size: 14px;
  `;

  const SuccessMessage = styled.p`
    color: green;
    font-size: 14px;
  `;

  useEffect(() => {
    getEstados();
    getGeneros();
    getEscolaridades();
    getSecciones(1);
    getMunicipios(1);
    getDatosPersonales();
  }, []);

  const handleChangeModal = (valor) =>{
    setShowModal(true);
    setValor(valor);
  }

  const getGeneros = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetGeneros.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setGeneros(data.generos);
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getEscolaridades = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetEscolaridad.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setEscolaridades(data.data);
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getSecciones = (estado) => {
    fetch('https://novomediaapp.azurewebsites.net/GetSecciones.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({estado}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSecciones(data.secciones);
        } else {
          console.error('Error al obtener las secciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getMunicipios = (estado) => {
    fetch('https://novomediaapp.azurewebsites.net/GetMunicipios.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({estado}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setMunicipios(data.data);
        } else {
          console.error('Error al obtener las secciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getEstados = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetEstados.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setEstados(data.estados);
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getDatosPersonales = async (e) => {
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_user,type: 'GetDatosPersonales' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
  
      const data = await response.json();
      if (data.success) {
        setDatosPersonales(data.data[0]);
        getSecciones(data.data[0]['estado_id']);
        getMunicipios(data.data[0]['estado_id']);
        setFormData({
          ...formData,
          nombre: data.data[0]['nombre'],
          a_paterno: data.data[0]['ap_paterno'],
          a_materno: data.data[0]['ap_materno'],
          fnac: data.data[0]['fec_nacimiento'],
          genero: data.data[0]['genero_id'],
          CP: data.data[0]['codigo_postal'],
          estado: data.data[0]['estado_id'],
          correoElectronico: data.data[0]['correo'],
          whatsapp: data.data[0]['whatsapp'], 
          escolaridad: data.data[0]['escolaridad'],
          seccion: data.data[0]['seccion_id'],
          municipio: data.data[0]['municipio'],
        });
      } 
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  }

  const handleSubmitDatosPer = async (e) => {
    e.preventDefault();
  
    if (formData.CP === '' ||formData.a_materno === '' ||formData.a_paterno === '' ||
      formData.avatar === '' ||formData.correoElectronico === '' ||formData.estado === '' ||
      formData.nombre === '' ||formData.escolaridad === '' ||formData.seccion === '' ||
      formData.genero === '' ||formData.fnac === '' || formData.whatsapp === ''){
      setError('Por favor completa todos los campos.');
      return;
    } 
  
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        body: JSON.stringify({formData,id_user,type:'UpdateUser' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
  
      const data = await response.json();
  
      if (data.success) {
        Swal.fire({
          title: "Deemby",
          text: 'Datos Actualizados correctamente',
          icon: "success"
        });
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = async () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
  
        // Crear un objeto FormData para enviar la imagen al backend
        const formDataToSend = new FormData();
        formDataToSend.append('image', file);
        formDataToSend.append('id_user', id_user);
  
        try {
          const response = await fetch('https://novomediaapp.azurewebsites.net/UpdateImage.php', {
            method: 'POST',
            body: formDataToSend,
          });
          const data = await response.json();
          if (data.success) {
            setError('');
          } else {
            setError(data.message);
          }
        } catch (error) {
          setError("Error");
        }
      }
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let filteredValue = value;
    let newValue = value;
  
    // Verificar si el valor ingresado contiene solo letras, espacios y la letra "ñ" usando una expresión regular
    if (name === 'nombre' || name === 'a_paterno' || name === 'a_materno') {
      newValue = value.replace(/[^A-Za-záéíóúñÁÉÍÓÚÑ\s]/ig, ''); // Remover cualquier caracter que no sea una letra, espacio o "ñ"
    }
  
    // Verificar si el campo es "avatar" y eliminar cualquier "@" ingresado
    if (name === 'avatar') {
      newValue = value.replace(/[@]/g, ''); // Remover cualquier "@"
    }
    
     // Validar CP para que tenga exactamente 5 dígitos
     if (name === 'CP') {
      // Remover cualquier caracter que no sea un número
      newValue = value.replace(/\D/g, '');
      // Limitar la longitud a 5 dígitos
      newValue = newValue.slice(0, 5);
    }
  
    // Verificar si el valor ingresado contiene solo números
    if (name === 'seccion') {
      newValue = value.replace(/\D/g, ''); // Remover cualquier caracter que no sea un número
    }
  
    // Lógica adicional para manejar estados, municipios y secciones
    if (name === 'estado') {
      getSecciones(value);
      getMunicipios(value);
    }
  
    if (name === 'seccion') {
      const seccionExists = true;//secciones.some((seccionObj) => seccionObj.secciones === value);
      setValidaSeccion(seccionExists);
    }

    // Si el campo es "whatsapp", aplicamos la lógica de filtrar solo números y permitir solo un signo de "+"
    if (name === 'whatsapp') {
      // Remueve todos los caracteres que no sean dígitos
      filteredValue = value.replace(/\D/g, '');
      // Verifica si hay más de un signo de "+" y, si es así, elimina los adicionales
      const plusCount = (filteredValue.match(/\+/g) || []).length;
      if (plusCount > 1) {
        filteredValue = filteredValue.replace(/\+/g, (match, offset, original) => offset ? '' : '+');
      }
    }
  
    // Validar el campo de correo electrónico con una expresión regular
    if (name === 'correoElectronico') {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Verificar si el valor ingresado coincide con el formato de correo electrónico
      if (!emailRegex.test(value)) {
        // El correo electrónico no tiene un formato válido, se puede manejar de diferentes formas, como mostrar un mensaje de error
        // Aquí simplemente lo dejaremos como está
        filteredValue = value;
      }
    }

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  return (
    <div className="container">
      <div>
        <h4 onClick={handleCollapseToggle} style={{ cursor: 'pointer' }}>Perfil</h4>
        <div style={{ display: collapseOpen ? 'block' : 'none',paddingLeft:'3%' }}>
          <h5 style={{ cursor: 'pointer' }} onClick={handleModalShow}>Datos Personales</h5>
        </div>
      </div>

      {/* Modal para datos personales */}
      <Modal
        isOpen={showModal}
        onRequestClose={handleModalClose}
        contentLabel="Datos Personales"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width:isDesktop?'60%':'80%'
          }
        }}
      >
        <h2>Datos Personales</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <form onSubmit={handleSubmitDatosPer} style={{overflowY:'scroll',overflowX:'hidden', maxHeight:'600px',paddingRight:'2%'}}>
          <div>
            {image ? (
              <img src={image} alt="Preview" className="img-fluid mb-3" style={{ width: 100, height: 100,cursor:'pointer' }} onClick={() => document.getElementById('image').click()} />
            ) : (
              <div className="d-flex align-items-center justify-content-center" style={{ width: 100, height: 100, border: '1px solid #ced4da',cursor:'pointer' }} onClick={() => document.getElementById('image').click()}>
                <FaPlus style={{ fontSize: 40, color: '#6c757d' }} />
              </div>
            )}
            <input type="file" id="image" name="image" accept="image/*" onChange={handleImageChange} className="form-control" style={{ display: 'none' }} />
            <b style={{fontSize:25,marginLeft:'20%'}}>{datosPersonales.nick}</b>
          </div>
          <div className="row mb-3">
            <div className="col">
              <InputGroup>
                <label>Nombre</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={formData.nombre || ''}
                  placeholder="Nombre(s)"
                  onChange={handleChange}
                  style={styles.input}
                  required={true}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label>Apellido Paterno</label>
              <input
                type="text"
                id="a_paterno"
                name="a_paterno"
                placeholder="Apellido Paterno"
                value={formData.a_paterno || ''}
                onChange={handleChange}
                style={styles.input}
                required={true}
              />
            </div>
            <div className="col">
              <label>Apellido Materno</label>
              <input
                type="text"
                id="a_materno"
                name="a_materno"
                placeholder="Apellido Materno"
                value={formData.a_materno || ''}
                onChange={handleChange}
                style={styles.input}
                required={true}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label>Fecha de nacimiento</label>
              <input
                type="date"
                id="fnac"
                name="fnac"
                value={formData.fnac || ''}
                onChange={handleChange}
                style={styles.input}
                required={true}
                max={'2007-01-01'}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label>Sexo</label>
              <InputGroup>
                <select
                  type="text"
                  id="genero"
                  name="genero"
                  value={formData.genero || ''}
                  onChange={handleChange}
                  style={styles.select}
                  required={true}
                >
                  <option value={''}>Sexo</option>
                  {generos.map((option, index) => (
                    <option value={option.id} key={index}>
                      {option.nombre}
                    </option>
                  ))}
                </select>
              </InputGroup>  
            </div>
            <div className="col">
              <label>Escolaridad</label>
              <select
                  type="text"
                  id="escolaridad"
                  name="escolaridad"
                  value={formData.escolaridad || ''}
                  onChange={handleChange}
                  style={styles.select}
                  required={true}
                >
                  <option value={''}>Escolaridad</option>
                  {escolaridades.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <label>Estado</label>
            <div className="col">
              <select
                type="text"
                id="estado"
                name="estado"
                value={formData.estado || ''}
                onChange={handleChange}
                style={styles.select}
                required={true}
              >
                <option value={''}>Estado</option>
                {estados.map((option, index) => (
                  <option value={option.id} key={index} >{option.nombre}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <label>Municipio</label>
            <div className="col">
              <select
                type="text"
                id="municipio"
                name="municipio"
                value={formData.municipio || ''}
                onChange={handleChange}
                style={styles.select}
                required={true}
              >
                <option value={''}>Alcaldía / Municipio</option>
                {municipios.map((option, index) => (
                  <option value={option.id} key={index} >{option.nombre}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label>Código Postal</label>
              <InputGroup>
                <input type="text" 
                  id="CP" 
                  name="CP" 
                  value={formData.CP || ''}
                  placeholder='Código Postal' 
                  minLength={5}
                  onChange={handleChange} 
                  style={styles.input} 
                  required={true}
                />
              </InputGroup>
            </div>
            <div className="col">
              <label>Sección Electoral</label>
              <InputGroup>
                <Input 
                  type="text"
                  id="seccion"
                  name="seccion"
                  value={formData.seccion}
                  placeholder='Sección Electoral'
                  onChange={handleChange}
                  style={{marginLeft:'1%',padding: '10px 20px',border: '1px solid #5c7cfa'}}
                  maxLength={10} // Limitar a 4 caracteres
                  required={true}
                />
                <InputGroupText>
                  <FaInfoCircle
                    style={{color:'#007AFF'}}
                    title="Información"
                    onClick={() => handleChangeModal(2)}
                  />
                </InputGroupText>
              </InputGroup>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label>Correo Electrónico</label>
              <input
                type="email"
                id="correoElectronico"
                name="correoElectronico"
                value={formData.correoElectronico || ''}
                placeholder="Correo Electrónico"
                onChange={handleChange}
                style={styles.input}
                required={true}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Guardar</button>
        </form>
      </Modal>
    </div>
  );
}

export default FormPerfil;
