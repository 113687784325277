import React, { useEffect,useState } from 'react';
import TopBar from './TopBarUser';
import Colmena from './colmena/Deemby';
import EstadoCuenta from './EstadoCuenta';

const Menu = ({isDesktop}) => {
  const [permisos, setPermisos] = useState([]);

  useEffect(() => {
    getSesion();
  }, []);

  const getSesion = async () => {
    const id_user = localStorage.getItem('id_user');
    if (id_user) {
      try {
        const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_user , type: 'Sesion' }),
        });
  
        if (!response.ok) {
          throw new Error('Error en la solicitud.');
        }
        const data = await response.json();
        if (data.success) {
          setPermisos(data.data);
        } else {
          console.log('El usuario no tiene permisos.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.log('El usuario no ha iniciado sesión.');
    }
  };

  return (
    <>
    {!isDesktop?
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ width: !isDesktop ? '100%' : '100%' }}>
          <TopBar isDesktop={!isDesktop}/>
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <Colmena data={permisos} sizeHexagon={!isDesktop ? 90 : 20} isDesktop={isDesktop}/>
          </div>
        </div>
        <div style={{ width: '30%' }}>
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <EstadoCuenta tipoUsuario={1} />
          </div>
        </div>
      </div>
      :
      <div>
        <div>
          <TopBar/>
        </div>
        <div style={{ width: '100%', marginTop: '100%'}}>
          <div>
            <Colmena data={permisos} sizeHexagon={60} isDesktop={isDesktop} />
          </div>
        </div>
        <div style={{ width: '100%',paddingLeft:'10%' }}>
          <EstadoCuenta tipoUsuario={1}/>
        </div>
      </div>
      }
    </>
  );
};

export default Menu;
