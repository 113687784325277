import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Deemby from './imagenes/deembyDes.png';
import ModalInfo from './ModalInfo';
import { useNavigate } from 'react-router-dom';
import { InputGroupText, Input, InputGroup } from 'reactstrap';
import { FaInfoCircle,FaEye,FaEyeSlash } from 'react-icons/fa';

const Logo = styled.img`
  width: 50%;
  height: auto;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  width: 100%;
`;

const styles = {
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px', // Agregamos un margen inferior para separar las filas
  },
  inputGroupPrepend: {
    display: 'flex', // Agregado
    alignItems: 'center', // Agregado
    backgroundColor: '#e9ecef',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    paddingLeft: '10px', // Agregado
    paddingRight: '10px', // Agregado
  },
  inputGroupText: {
    color: 'black',
    fontSize: '14px'
  },
  input: {
    padding: '10px 20px',
    margin: '5px',
    width: '100%',
    border: '1px solid #5c7cfa',
    borderRadius: '5px',
    fontSize: '14px'
  },
  select: {
    padding: '10px 20px',
    margin: '4px',
    width: '100%',
    border: '1px solid #5c7cfa',
    borderRadius: '5px',
    fontSize: '14px'
  },
  button: {
    padding: '10px 20px',
    margin: '5px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
};

const Form = ({isDesktop}) => {
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({});
  const [generos, setGeneros] = useState([]);
  const [escolaridades, setEscolaridades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [validaSeccion, setValidaSeccion] = useState(false);
  const [aceptaTerminos, setAceptaTerminos] = useState(false);
  const [passShow, setpassShow] = useState(false);
  const [valor, setValor] = useState(0);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleAceptaTerminosChange = () => {setAceptaTerminos(!aceptaTerminos);};
  var md5 = require('md5');

  useEffect(() => {
    getEstados();
    getSecciones(1);
    getMunicipios(1);
    getGeneros();
    getEscolaridades();
  }, []);

  const handleChangeModal = (valor) =>{
    setShowModal(true);
    setValor(valor);
  }

  const onSubmit = async (e) => {
    e.preventDefault(); // Detener el comportamiento predeterminado del formulario
    if (formData.contrasena !== formData.confirmarcontrasena) {
      setError('Las contrasenas no coinciden.');
      return;
    }

    if(!aceptaTerminos){
      setError('Debes aceptar los términos y condiciones para continuar');
      return;
    }

    if (!validatePassword(formData.contrasena)) {
      let errorMessage = 'La contraseña debe cumplir con los siguientes requisitos:\n';
      if (formData.contrasena.length < 8) {
        errorMessage += '- Tener al menos 8 caracteres.\n';
      }
      if (!/[A-Z]/.test(formData.contrasena)) {
        errorMessage += '- Contener al menos una letra mayúscula.\n';
      }
      if (!/[a-z]/.test(formData.contrasena)) {
        errorMessage += '- Contener al menos una letra minúscula.\n';
      }
      if (!/\d/.test(formData.contrasena)) {
        errorMessage += '- Contener al menos un número.\n';
      }
      if (!/[!@#$%^&*()_+]/.test(formData.contrasena)) {
        errorMessage += '- Contener al menos un carácter especial (!@#$%^&*()_+).\n';
      }
      setError(errorMessage);
      return;
    }

    if (formData.CP === '' ||formData.a_materno === '' ||formData.a_paterno === '' ||
      formData.avatar === '' ||formData.confirmarcontrasena === '' ||formData.contrasena === '' ||
      formData.correoElectronico === '' ||formData.estado === '' ||formData.fnac === '' ||
      formData.nombre === '' ||formData.escolaridad === '' ||formData.seccion === '' ||
      formData.genero === '' ||formData.whatsapp === ''){
      setError('Por favor completa todos los campos.');
      return;
    }

    const hashedPass = md5(formData.contrasena);
    
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({formData, pass: hashedPass,type:'SaveUser' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
      const data = await response.json();
      if (data.status === "success") {
        navigate(`/ValidateCode/${data.message}`);
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let filteredValue = value;
    let newValue = value;
  
    // Verificar si el valor ingresado contiene solo letras, espacios y la letra "ñ" usando una expresión regular
    if (name === 'nombre' || name === 'a_paterno' || name === 'a_materno') {
      newValue = value.replace(/[^A-Za-záéíóúñÁÉÍÓÚÑ\s]/ig, ''); // Remover cualquier caracter que no sea una letra, espacio o "ñ"
    }
  
    // Verificar si el campo es "avatar" y eliminar cualquier "@" ingresado
    if (name === 'avatar') {
      newValue = value.replace(/[@]/g, ''); // Remover cualquier "@"
    }
    
     // Validar CP para que tenga exactamente 5 dígitos
     if (name === 'CP') {
      // Remover cualquier caracter que no sea un número
      newValue = value.replace(/\D/g, '');
      // Limitar la longitud a 5 dígitos
      newValue = newValue.slice(0, 5);
    }
  
    // Verificar si el valor ingresado contiene solo números
    if (name === 'seccion') {
      newValue = value.replace(/\D/g, ''); // Remover cualquier caracter que no sea un número
    }
  
    // Lógica adicional para manejar estados, municipios y secciones
    if (name === 'estado') {
      getSecciones(value);
      getMunicipios(value);
    }
  
    if (name === 'seccion') {
      const seccionExists = true;//secciones.some((seccionObj) => seccionObj.secciones === value);
      setValidaSeccion(seccionExists);
    }

    // Si el campo es "whatsapp", aplicamos la lógica de filtrar solo números y permitir solo un signo de "+"
    if (name === 'whatsapp') {
      // Remueve todos los caracteres que no sean dígitos
      filteredValue = value.replace(/\D/g, '');
      // Verifica si hay más de un signo de "+" y, si es así, elimina los adicionales
      const plusCount = (filteredValue.match(/\+/g) || []).length;
      if (plusCount > 1) {
        filteredValue = filteredValue.replace(/\+/g, (match, offset, original) => offset ? '' : '+');
      }
    }
  
    // Validar el campo de correo electrónico con una expresión regular
    if (name === 'correoElectronico') {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Verificar si el valor ingresado coincide con el formato de correo electrónico
      if (!emailRegex.test(value)) {
        // El correo electrónico no tiene un formato válido, se puede manejar de diferentes formas, como mostrar un mensaje de error
        // Aquí simplemente lo dejaremos como está
        filteredValue = value;
      }
    }

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return regex.test(password);
  };

  const getGeneros = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetGeneros.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setGeneros(data.generos);
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getEscolaridades = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetEscolaridad.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setEscolaridades(data.data);
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getSecciones = (estado) => {
    fetch('https://novomediaapp.azurewebsites.net/GetSecciones.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({estado}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSecciones(data.secciones);
        } else {
          console.error('Error al obtener las secciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getMunicipios = (estado) => {
    fetch('https://novomediaapp.azurewebsites.net/GetMunicipios.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({estado}),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setMunicipios(data.data);
        } else {
          console.error('Error al obtener las secciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getEstados = () => {
    fetch('https://novomediaapp.azurewebsites.net/GetEstados.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setEstados(data.estados);
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const ShowPass = () => {
    setpassShow(!passShow);
  }

  

  return (
    <div style={{ width: '100%', paddingLeft:'15%', paddingRight: '15%' }}>
      <Logo src={Deemby} alt="Logo" style={{marginTop:'40%',marginBottom:'5%'}}/>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
        <form onSubmit={onSubmit} style={{marginBottom:'5%', paddingLeft:isDesktop?'15%':'0%', paddingRight:isDesktop?'15%':'0%'}}>
          <div style={styles.inputGroup}>
            <InputGroup>
              <InputGroupText>
                @
              </InputGroupText>
              <Input 
                type="text" 
                id="avatar" 
                name="avatar" 
                placeholder="usuari@" 
                value={formData.avatar} 
                onChange={handleChange} 
                style={{padding: '10px 20px',border: '1px solid #5c7cfa'}} // Añadido marginLeft
                required={true}
                minLength={3}
                maxLength={15}
                pattern="[^\s]+"
              />
              <InputGroupText>
                <FaInfoCircle
                  style={{color:'#007AFF'}}
                  title="Información"
                  onClick={() => handleChangeModal(1)}
                />
              </InputGroupText>
            </InputGroup>
          </div>
          <div>
            <InputGroup>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre || ''}
                placeholder="Nombre(s)"
                onChange={handleChange}
                style={styles.input}
                required={true}
              />
            </InputGroup>
          </div>
          <div style={styles.inputGroup}>
            <input
              type="text"
              id="a_paterno"
              name="a_paterno"
              placeholder="Apellido Paterno"
              value={formData.a_paterno || ''}
              onChange={handleChange}
              style={styles.input}
              required={true}
            />
            <input
              type="text"
              id="a_materno"
              name="a_materno"
              placeholder="Apellido Materno"
              value={formData.a_materno || ''}
              onChange={handleChange}
              style={styles.input}
              required={true}
            />
          </div>
          <div style={styles.inputGroup}>
            <label>Fecha de nacimiento</label>
            <input
              type="date"
              id="fnac"
              name="fnac"
              value={formData.fnac || ''}
              onChange={handleChange}
              style={styles.input}
              required={true}
              max={'2007-01-01'}
            />
          </div>
          <div style={styles.inputGroup}>
            <select
              type="text"
              id="estado"
              name="estado"
              value={formData.estado || ''}
              onChange={handleChange}
              style={styles.select}
              required={true}
            >
              <option value={''}>Estado</option>
              {estados.map((option, index) => (
                <option value={option.id} key={index} >{option.nombre}</option>
              ))}
            </select>
          </div>
          <div style={styles.inputGroup}>
            <select
              type="text"
              id="municipio"
              name="municipio"
              value={formData.municipio || ''}
              onChange={handleChange}
              style={styles.select}
              required={true}
            >
              <option value={''}>Alcaldía / Municipio</option>
              {municipios.map((option, index) => (
                <option value={option.id} key={index} >{option.nombre}</option>
              ))}
            </select>
          </div>
          <div style={styles.inputGroup}>
            <InputGroup>
              <input type="text" 
                id="CP" 
                name="CP" 
                value={formData.CP || ''}
                placeholder='Código Postal' 
                minLength={5}
                onChange={handleChange} 
                style={styles.input} 
                required={true}
              />
            </InputGroup>
            <InputGroup>
              <Input 
                type="text"
                id="seccion"
                name="seccion"
                value={formData.seccion}
                placeholder='Sección Electoral'
                onChange={handleChange}
                style={{marginLeft:'1%',padding: '10px 20px',border: '1px solid #5c7cfa'}}
                maxLength={10} // Limitar a 4 caracteres
                required={true}
              />
              <InputGroupText>
                <FaInfoCircle
                  style={{color:'#007AFF'}}
                  title="Información"
                  onClick={() => handleChangeModal(2)}
                />
              </InputGroupText>
            </InputGroup>
          </div>
          <div style={styles.inputGroup}>
            <InputGroup>
              <select
                type="text"
                id="genero"
                name="genero"
                value={formData.genero || ''}
                onChange={handleChange}
                style={styles.select}
                required={true}
              >
                <option value={''}>Sexo</option>
                {generos.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.nombre}
                  </option>
                ))}
              </select>
            </InputGroup>
            <select
                type="text"
                id="escolaridad"
                name="escolaridad"
                value={formData.escolaridad || ''}
                onChange={handleChange}
                style={styles.select}
                required={true}
              >
                <option value={''}>Escolaridad</option>
                {escolaridades.map((option, index) => (
                <option value={option.id} key={index}>
                  {option.nombre}
                </option>
              ))}
            </select>
          </div>
          <div style={styles.inputGroup}>
            <InputGroup>
              <Input 
                type="text" 
                id="whatsapp" 
                name="whatsapp" 
                value={formData.whatsapp} 
                placeholder='WhatsApp' 
                onChange={handleChange} 
                maxLength={12}
                required={true}
                style={{marginLeft:'1%',padding: '10px 20px',border: '1px solid #5c7cfa'}}
              />
            <InputGroupText>
              <FaInfoCircle
                style={{color:'#007AFF'}}
                title="Información"
                onClick={() => handleChangeModal(4)}
              />
            </InputGroupText>
          </InputGroup>
          </div>
          <div style={styles.inputGroup}>
            <input
              type="email"
              id="correoElectronico"
              name="correoElectronico"
              value={formData.correoElectronico || ''}
              placeholder="Correo Electrónico"
              onChange={handleChange}
              style={styles.input}
              required={true}
            />
          </div>
          <div style={styles.inputGroup}>
            <InputGroup>
              <Input 
                type={passShow?'text':"password"} 
                id="contrasena" 
                name="contrasena" 
                value={formData.contrasena} 
                placeholder='Contraseña' 
                onChange={handleChange} 
                required={true}
                style={{marginLeft:'1%',padding: '10px 20px',border: '1px solid #5c7cfa'}}
              />
              <InputGroupText>
                {!passShow?
                  <FaEye
                    style={{color:'#007AFF'}}
                    title="Información"
                    onClick={ShowPass}
                  />:
                  <FaEyeSlash
                    style={{color:'#007AFF'}}
                    title="Información"
                    onClick={ShowPass}
                  />
                }
              </InputGroupText>
              <InputGroupText>
                <FaInfoCircle
                  style={{color:'#007AFF'}}
                  title="Información"
                  onClick={() => handleChangeModal(3)}
                />
              </InputGroupText>
            </InputGroup>
          </div>
          <div style={styles.inputGroup}>
            <InputGroup>
              <input
                type="password"
                id="confirmarcontrasena"
                name="confirmarcontrasena"
                value={formData.confirmarcontrasena || ''}
                placeholder="Confirmar Contraseña"
                onChange={handleChange}
                style={styles.input}
                required={true}
                minLength={6}
              />
            </InputGroup>
          </div>
          <div style={{textAlign:'center'}}>
            <br/>
            <input
              type="checkbox"
              id="aceptaTerminos"
              name="aceptaTerminos"
              checked={aceptaTerminos}
              onChange={handleAceptaTerminosChange}
              style={{ marginRight: '5px' }}
            />
            <label htmlFor="aceptaTerminos" style={{color:'#007AFF',cursor:'pointer'}} 
            onClick={() => handleChangeModal(5)}>Acepto los términos y condiciones</label>
          </div><br/>
          <ModalInfo id={valor} isOpen={showModal}  onClose={handleModalClose} />
          <div style={{textAlign:'center'}}>
            <button type="submit" style={styles.button}>
              Guardar
            </button>
          </div>
        </form>
    </div>
  );
};

export default Form;
