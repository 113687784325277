import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import HomeDes from './HomeDes';
import NewVTRDes from './NewVTR';
import Welcome from './Welcome';
import LoginDes from './LoginDes';
import RecoverPass from './RecoverPass';
import ValidateCodeDes from './ValidateCodeDes';
import ValidateCodeRP from './ValidateCodeRP';
import FormExtraData from './FormExtraDataU';
import FormPass from './FormPass';
import Menu from './Menu';
import Notificationes from './FormNotificaciones';

const AppContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopContainer = styled.div`
  width: 100%;
`;

const MovilContainer = styled.div`
  width: 100%;
  color: white;
`;

const App = () => {
  const [isMobile, setIsDesktop] = useState(false);
  const [isValidSession, setIsValidSession] = useState(false);
  const [userPass, setUserPass] = useState(0);

  useEffect(() => {
    const mobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    setIsDesktop(mobile);
    const id_user = localStorage.getItem('id_user');
    
    if(id_user){
      setIsValidSession(false);
      localStorage.setItem('token', null);
      getSesion(id_user);
    }
  }, []);

  const getSesion = (id_user) => {
    const data = {'type':'ChangeToken',id_user}
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if(data.success){
        localStorage.setItem('token', data.token);
        localStorage.setItem('id_user', data.usuario_id);
        setIsValidSession(true);
      }else{
        setIsValidSession(false);
        localStorage.setItem('token', null);
        localStorage.setItem('id_user', null);
      }
      
    })
    .catch(error => console.error('Error de red:', error));
  }  
  
  return (
    <AppContainer style={{backgroundColor:'white'}}>
        <DesktopContainer>
          <Router>
            <Routes>
              <Route path="/" element={<HomeDes  isDesktop={!isMobile}/>} />
              <Route path="/Login" element={<LoginDes isDesktop={isMobile}/>} />
              <Route path="/Menu" element={<Menu isDesktop={isMobile}/>} />
              <Route path="/Welcome" element={<Welcome  isDesktop={isMobile}/>} />
              <Route path="/NewVTR" element={<NewVTRDes isDesktop={!isMobile}/>} />
              <Route path="/ValidateCode/:IdUser" element={<ValidateCodeDes isDesktop={isMobile}/>} />
              <Route path="/RecovePass" element={<RecoverPass isDesktop={isMobile}/>} />
              <Route path="/ValidateCodeRP/:IdUser" element={<ValidateCodeRP isDesktop={isMobile} setUserPass={setUserPass}/>} />
              <Route path="/FormPass" element={<FormPass isDesktop={isMobile} id_user={userPass}/>} />
              <Route path="/FormExtraData" element={<FormExtraData isDesktop={!isMobile}/>} />
              <Route path="/Notificationes" element={<Notificationes isDesktop={isMobile}/>} />
            </Routes>
          </Router>
        </DesktopContainer>
    </AppContainer>
  );
};
export default App;
