import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 
import Deemby from './imagenes/deembyDes.png';

const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  color:white;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Input = styled.input`
  flex: 2;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const Logo = styled.img`
  width: 40%; 
  height: auto;
  margin-bottom: 20%;
`;

const Form = () => {
  const navigate = useNavigate(); 
  const [error, setError] = useState('');

  const [user, setUser] = useState('');

  const handleUser = (event) => {
    setUser(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (
      user === ''
    ) {
      setError('Por favor completa todos los campos.');
      return;
    }
    
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user, type: 'RecoverPass' }),
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
      const data = await response.json();
      if (data.success) {
        navigate(`/ValidateCodeRP/${data.message}`);
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  return (
    <FormContainer>
      <Logo src={Deemby} alt="Logo" />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Input type="text" id="user" name="user"  placeholder='Usuari@' value={user} onChange={handleUser} />
        </FormGroup>
        <FormGroup>
          <Button type="submit">Recuperar Contraseña</Button>
        </FormGroup>
      </form>
    </FormContainer>
  );
};

export default Form;
