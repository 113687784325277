import React, { useEffect,useState } from 'react';
import styled from 'styled-components';
import Deemby from './imagenes/deembyDes.png';
import ModalInfo from './ModalInfo';
import Buro from './imagenes/buro.svg';
import InfoC from './imagenes/infocandiadtos.svg';
import InfoG from './imagenes/Infogobierno.svg';
import { useNavigate } from 'react-router-dom'; 

const DesktopHomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Logo = styled.img`
  width: 50%; /* Ajusta el tamaño según sea necesario */
  height: auto; /* Para mantener la proporción */
  margin-top: 8%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Cambiamos a dirección de columna */
`;

const Button = styled.button`
  padding: 15px 40px; /* Ajustamos el padding para hacer los botones más alargados */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px; /* Agregamos margen inferior para separar los botones */
`;

const BlueButton = styled(Button)`
  background-color: #5c7bfa;
  color: white;
`;

const Text = styled.p`
  margin-bottom: 10px;
  color: #5E7BFC;
`;

const Footer = styled.footer`
  margin-top: auto;
  text-align: center;
`;

const Link = styled.a`
  color: #000; /* Color de texto negro */
  text-decoration: none; /* Quitamos el subrayado */
  margin-right: 30px; /* Espacio entre los enlaces */
`;

const DesktopHome = ({isDesktop}) => {
  const navigate = useNavigate(); 
  const id_user = localStorage.getItem('id_user');
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const [valor, setValor] = useState(0);
  const handleChangeModal = (valor) =>{
    setShowModal(true);
    setValor(valor);
  }

  useEffect(() => {
    if (id_user > 0 && token !== null) {
      navigate('/Menu');
    }
  }, [id_user, token, navigate]);

  return (
    <DesktopHomePage>
      <Logo src={Deemby} alt="Logo" />
      <Text>Una plataforma, posibilidades infinitas</Text>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img src={Buro} alt="Buro Parlamentario" style={{ width: '33%'}} />
        <img src={InfoC} alt="Candidatos Info" style={{ width: '33%'}} />
        <img src={InfoG} alt="Gobierno Info" style={{ width: '33%' }} />
      </div>
      <p style={{color:'#158C52'}}>Integraciones disponibles</p>
      <ButtonContainer>
        <BlueButton onClick={() => navigate('/Login')}>Iniciar Sesión</BlueButton>
        <BlueButton onClick={() => navigate('/NewVTR')}>Crear tu usuari@</BlueButton>
      </ButtonContainer>
      <Text style={{color:'#868E89',textAlign:'center'}}>
        Tus datos serán utilizados únicamente para personalizar tu navegación <br/> dentro de la colmena, 
        Deemby es un lugar libre de publicidad y FakeNews.
      </Text>
      <Footer>
        <p>
          <Link href="#">¿Qué es Deemby?</Link>
          <Link href="#">Ayuda</Link>
          <Link style={{cursor:'pointer'}} onClick={() => handleChangeModal(5)}>Términos y condiciones</Link>
          <Link href="#">FAQs</Link>
        </p>
        <p>
          <Link href="#">Deemby® 2024</Link>
        </p>
      </Footer>
      <ModalInfo id={valor} isOpen={showModal}  onClose={handleModalClose} />
    </DesktopHomePage>
  );
};

export default DesktopHome;
