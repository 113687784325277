import React, { useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom'; 
import './EstadoCuenta.css'; 

const EstadoCuenta = ({ tipoUsuario }) => {
  const navigate = useNavigate(); 
  const [deembys, setDeembys] = useState([]);
  const [imagen, setImagen] = useState(null);
  const id_user = localStorage.getItem('id_user');
  
  
  useEffect(() => {
    getDeembys();
    setImagen('https://novomediaapp.azurewebsites.net/assents/user/'+id_user+'.jpg');
  }, []);

  const getDeembys = async () => {
    if (id_user) {
      try {
        const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_user , type: 'GetDeemby' }),
        });
  
        if (!response.ok) {
          throw new Error('Error en la solicitud.');
        }
  
        const data = await response.json();
  
        if (data.success) {
          setDeembys(data.data[0]);
        } else {
          console.log('El usuario no tiene deembyes.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      navigate('/')
    }
  }

  return (
    <div className="estado-cuenta">
      <div className="datos" style={{marginLeft:'1%'}}>
        <div className="imagen-container">
          {imagen ? (
            <img src={imagen} alt="Imagen de perfil" style={{borderRadius:'5%'}} />
          ) : (
            <div className="sin-imagen">
              Sin Foto 
            </div>
          )}
        </div>
        <b style={{color:'#5D7CF9'}}><br/>{deembys.nombre} {deembys.ap_paterno} {deembys.ap_materno}</b>
      </div>
      <div className="datos">
        <table>
          <tbody>
            <tr>
              <td><strong>Deembys</strong></td>
              <td>{deembys.dembys}</td>
            </tr>
            <tr>
              <td><strong>Badge</strong></td>
              <td> {deembys.insignias}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*<div className="datos">
        <table>
          <tbody>
            <tr>
              <td><strong>Total Deembys</strong></td>
              <td><strong>{deembys.dembys}</strong></td>
            </tr>
          </tbody>
        </table>
        </div>*/}
      <div className="linea-tiempo">
        {["blue", "pink", "yellow", "green"].map((color, index) => (
          <div className="hexagon-container" key={index}>
            <div>
              <img style={{height:'80px'}} src={`https://novomediaapp.azurewebsites.net/assents/deemby_tipos_usuarios/hex${index + 1}.svg`} />
            </div>
            <div className="color-name" style={{color:color}}>{color}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EstadoCuenta;
