import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';
import styled from 'styled-components';
var md5 = require('md5');

function FormSeguridad() {
  const id_user = localStorage.getItem('id_user');
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    contrasena: '',
    confirmarcontrasena: '',
    oldcontrasena:''
  });

  const [datosPersonales, setDatosPersonales] = useState([]);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  const handleCollapseToggle = () => setCollapseOpen(!collapseOpen);

  const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 14px;
`;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return regex.test(password);
  };

  const getDatosPersonales = async () => {
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_user,type: 'GetDatosPersonales' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
  
      const data = await response.json();
      if (data.success) {
        setDatosPersonales(data.data[0]);
      } 
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  }

  const handleSubmitSeg = async (e) => {
    e.preventDefault();
    if (formData.contrasena !== formData.confirmarcontrasena) {
      setError('Las contrasenas no coinciden.');
      return;
    }

    if (!validatePassword(formData.contrasena)) {
      setError('La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un número, un carácter especial y tener una longitud mínima de 8 caracteres.');
      return;
    }

    if(md5(formData.oldcontrasena)!==datosPersonales.contrasena){
      setError('La contraseña anterior no coincide.');
      return;
    }
    
    const Newpass = md5(formData.contrasena);
    
    try {
      setError('');
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Newpass,type: 'UpdatePass',token,id_user }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
      const data = await response.json();
      if (data.success) {
        setSuccess('Cambios realizados correctamente');
      } 
    }catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  useEffect(() => {
    getDatosPersonales();
  }, []);

  return (
    <div className="container">
      <div>
        <h4 onClick={handleCollapseToggle} style={{ cursor: 'pointer' }}>Seguridad</h4>
        <div style={{ display: collapseOpen ? 'block' : 'none',paddingLeft:'3%' }}>
          <h5 style={{ cursor: 'pointer' }} onClick={handleModalShow}>Cambio de Contraseña</h5>
        </div>
      </div>

      {/* Modal para datos personales */}
      <Modal
        isOpen={showModal}
        onRequestClose={handleModalClose}
        contentLabel="Datos Personales"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
          }
        }}
      >
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        <h2>Cambio de Contraseña</h2>
          <form onSubmit={handleSubmitSeg}>
            <div className="mb-3">
              <div className="col">
                <input type="password" id="oldcontrasena" name="oldcontrasena" 
                  placeholder='Contraseña Actual' value={formData.oldcontrasena} 
                  onChange={handleChange} className='form-control' required/>  
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <input type="password" id="Nueva Contrasena" name="contrasena" placeholder='Nueva Contraseña' 
                value={formData.contrasena} onChange={handleChange} className='form-control' required/>
              </div>
              <div className="col">
                <input type="password" id="confirmarcontrasena" name="confirmarcontraseña" 
                placeholder='Confirmar Nueva Contrasena' value={formData.confirmarcontrasena} 
                onChange={handleChange} className='form-control' required/>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">Cambiar Contraseña</button>
          </form>
      </Modal>
    </div>
  );
}

export default FormSeguridad;
