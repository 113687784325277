import React, { useState,useEffect } from 'react';
import Deemby from './imagenes/deembyDes.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 
import { useParams } from 'react-router-dom';
import { InputGroup } from 'reactstrap';
import Modal from 'react-modal';
import Swal from 'sweetalert2'

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;`;


const Input = styled.input`
  flex: 2;
  width: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
`;

const Logo = styled.img`
  width: 30%; /* Ajusta el tamaño según sea necesario */
  height: auto; /* Para mantener la proporción */
`; 

const ValidateCode = () => {
  const navigate = useNavigate(); 
  const [code, setCode] = useState(['', '', '', '']);
  const [isOpen, setisOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [number, setNumber] = useState('');
  const { IdUser } = useParams();
  
  useEffect(() => {
    getNumber();
  }, []);

  const handleChangeModalData = (e) => {
    const { name, value } = e.target;
    let filteredValue = value;
    let newValue = value;
  
    if (name === 'whatsapp') {
      filteredValue = value.replace(/\D/g, '');
      const plusCount = (filteredValue.match(/\+/g) || []).length;
      if (plusCount > 1) {
        filteredValue = filteredValue.replace(/\+/g, (match, offset, original) => offset ? '' : '+');
      }
    }

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  const handleChangeModal = () =>{
    setisOpen(!isOpen);
  }
  
  const handleChange = (e, index) => {
    const newCode = e.target.value.toUpperCase(); // Convertir a mayúscula
    if (/^[a-zA-Z0-9]*$/.test(newCode) && newCode.length <= 1) {
      const newCodeArr = [...code];
      newCodeArr[index] = newCode;
      setCode(newCodeArr);
      setError('');
      // Mover el foco al siguiente campo
      if (index < 3) {
        const nextInput = document.getElementById(`char-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const sendCode = () => {
    fetch('https://novomediaapp.azurewebsites.net/UpdateCode.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({IdUser:IdUser})
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('codigo reenviado');
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const enteredCode = code.join('');
    if (enteredCode.length !== 4) {
      setError('Completa el código');
      return;
    }else{
      fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({enteredCode,IdUser:IdUser,type:'ValideCode'})
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            navigate('/Login');
          } else {
            alert(data.message);
          }
        })
        .catch(error => console.error('Error de red:', error));
    }
    
  };

  const updateNumber = () => {
    setError('');
    if (formData.whatsapp !== formData.Cwhatsapp) {
      setError('Las números no son iguales.');
      return;
    }

    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id_user:IdUser,type:'UpdateNumber',formData})
    })
      .then(response => response.json())
      .then(data => {
        if(data.success){
          handleChangeModal();  
          Swal.fire({
            title: "Deemby",
            text: 'Se ha actualizado su WhatsApp y se ha enviado su codigo correctamente ',
            icon: "success"
          });
        }else{
          Swal.fire({
            title: "Deemby",
            text: 'Error al Actualizar su WhatsApp, por favor vuelva a intentar',
            icon: "error"
          });
        }
      })
      .catch(error => console.error('Error de red:', error));
  }

  const getNumber = () => {
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id_user:IdUser,type:'GetDatosPersonales'})
    })
      .then(response => response.json())
      .then(data => {
        setNumber(data.data[0].whatsapp);
      })
      .catch(error => console.error('Error de red:', error));
  };

  return (
    <div style={{ textAlign: 'center',padding:'20%'}}>
      <Logo src={Deemby} alt="Logo" />
      <h1>Código de confirmación</h1>
      <h4>Hemos enviado un codigo al telefono <br/><br/><p style={{color:'green'}}>{number}</p></h4>
      <h4>Necesitamos confirmar la creación de tu avatar. </h4>
      <h4>Introduce el código que te enviamos al número telefónico que registraste.</h4>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {code.map((char, index) => (
            <Input
              key={index}
              id={`char-${index}`}
              type="text"
              value={char}
              maxLength={1}
              onChange={(e) => handleChange(e, index)}
              style={{ margin: '4px', padding: '20px', width: '10%', textAlign: 'center', fontSize: '20px',borderColor: '#5c7cfa' }}
            />
          ))}
        </div>
        <br />
        <Button type="submit" style={{ margin: '10px', padding: '20px' }}>Confirmar Avatar</Button>
        
      </form>
        <Button onClick={handleChangeModal} style={{ margin: '10px', padding: '20px',backgroundColor:'green' }}>No es mi número</Button>
        <h4>¿No recibiste el código?</h4>
        <p style={{color:'#007bff',cursor:'pointer'}} onClick={sendCode}>Reenviar Código</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <Modal
        isOpen={isOpen}
        onRequestClose={handleChangeModal}
        contentLabel="Datos"
        style={{
          content: {
            width: '80%',
            height:'50%',
            left: '10%',
            right: '10%',
            top: '15%',
          },
        }}
      >
        <h5>Capture su número correcto de WhatsApp</h5>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <br/>
        <InputGroup>
          <Input 
            type="text" 
            id="whatsapp" 
            name="whatsapp" 
            value={formData.whatsapp} 
            placeholder='WhatsApp' 
            onChange={handleChangeModalData} 
            maxLength={12}
            required={true}
            style={{marginLeft:'1%',padding: '10px 20px',border: '1px solid #5c7cfa'}}
          />
        </InputGroup>
        <br/>
        <InputGroup>
          <Input 
            type="text" 
            id="Cwhatsapp" 
            name="Cwhatsapp" 
            value={formData.Cwhatsapp} 
            placeholder='Confirme su WhatsApp' 
            onChange={handleChangeModalData} 
            maxLength={12}
            required={true}
            style={{marginLeft:'1%',padding: '10px 20px',border: '1px solid #5c7cfa'}}
          />
        </InputGroup>
        <br/>
        <div style={{textAlign:'center'}}>
          <Button onClick={updateNumber}>
            Guardar
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ValidateCode;
