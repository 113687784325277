import React, { useState } from 'react';
import styled from 'styled-components';
import Deemby from './imagenes/deembyDes.png';
import { useNavigate } from 'react-router-dom'; 
import { Input, Button } from 'reactstrap';

var md5 = require('md5');

const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  color:#0D6EFD;
`;


const Logo = styled.img`
  width: 40%; 
  height: auto;
  margin-bottom: 20%;
`;


const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const FormPass = ({id_user}) => {
  const navigate = useNavigate(); 
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({ cpass: '', pass: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { cpass, pass } = formData;
  
    if (!pass || !cpass) {
      setError('Por favor completa todos los campos.');
      return;
    }
  
    if (pass !== cpass) {
      setError('Las contrasenas no coinciden.');
      return;
    }

    if (!validatePassword(pass)) {
      let errorMessage = 'La contraseña debe cumplir con los siguientes requisitos:\n';
      if (pass.length < 8) {
        errorMessage += '- Tener al menos 8 caracteres.\n';
      }
      if (!/[A-Z]/.test(pass)) {
        errorMessage += '- Contener al menos una letra mayúscula.\n';
      }
      if (!/[a-z]/.test(pass)) {
        errorMessage += '- Contener al menos una letra minúscula.\n';
      }
      if (!/\d/.test(pass)) {
        errorMessage += '- Contener al menos un número.\n';
      }
      if (!/[!@#$%^&*()_+]/.test(pass)) {
        errorMessage += '- Contener al menos un carácter especial (!@#$%^&*()_+).\n';
      }
      setError(errorMessage);
      return;
    }

    const hashedPass = md5(pass);
  
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id_user,pass: hashedPass, type: 'ChangePass' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }
      const data = await response.json();
      if (data.success) {
        navigate('/Login');
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Hubo un problema con la solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return regex.test(password);
  };
  
  return (
    <FormContainer>
      <Logo src={Deemby} alt="Logo" />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleSubmit}>
        <h4>Cambia tu contraseña</h4>
        <Input 
          type="password" 
          name="pass" 
          className="form-control" 
          placeholder="Contraseña" 
          value={formData.pass} 
          onChange={handleChange} 
        /><br/>
        <Input 
          type="password" 
          name="cpass"  
          placeholder="Confirma Contraseña" 
          className="form-control"  
          value={formData.cpass} 
          onChange={handleChange} 
        /><br/>
        <div style={{textAlign:'center'}} >
          <Button type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};

export default FormPass;
