import {useEffect, useState} from "react";

const Deemby = ({data}) => {

    const [scale, setScale] = useState(0.8);
    const hexagons = [
        [1],
        [2, 3],
        [4, 5, 6],
        [7, 8, 9, 10],
        [11, 12, 13, 14, 15],
        [16, 17, 18, 19],
        [20, 21, 22],
        [23, 24],
        [25],
    ]
    const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(navigator.userAgent);

    useEffect(() => {
        const updateScale = () => {
            if(isMobile) return;
            const width = window.innerWidth;
            // Aquí puedes ajustar la lógica para cambiar el valor de scale en función del ancho de la ventana
            if (width < 1000) {
                setScale(0.7);
            } else if (width < 1200) {
                setScale(0.8);
            } else {
                setScale(0.9);
            }
            console.log(width)
        };

        // Actualizar el scale al montar el componente
        updateScale();

        // Actualizar el scale cada vez que cambie el tamaño de la ventana
        window.addEventListener('resize', updateScale);

        // Limpiar el event listener al desmontar el componente
        return () => window.removeEventListener('resize', updateScale);
    }, []);
    return (
        <div style={{
            width: "100%",
            transform: `scale(${scale})`,
            display: "flex",
            justifyContent: "center",
        }}>
            <div style={{
                display: "flex",
            }}>
                {hexagons.map((hexagonRow, index) => {
                    if (isMobile && (index <= 2 || index > 5)) return null;
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",

                            }}>
                            {hexagonRow.map((hexagon, index) => {
                                const sistema = data.find((item) => item.posicion == hexagon);
                                if (isMobile && !sistema?.sistema_logo) {
                                    return <div style={{
                                        width: 120, height: 120
                                    }}>
                                    </div>
                                }
                                return (
                                    <IconHexa {...sistema}/>
                                )
                            })
                            }
                        </div>
                    )
                })}
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style={{
                    position: "absolute",
                    width: 0,
                    height: 0,

                }}
            >
                <defs>
                    <clipPath id="clip-path"
                    >
                        <path
                            fill="none"
                            strokeWidth={1}
                            d="m -0.00778156,60.110626 1.59326416,-7.760099 21.3441724,-37.5588 7.081789,-6.0934481 7.600857,-2.760259 44.359489,-0.0654 9.195474,3.289655 7.474655,6.4633871 20.862851,38.404207 0.48856,6.080762 -0.4688,7.021285 -20.931968,36.755754 -7.325825,6.88757 -7.217567,3.08149 -46.259459,0.67414 c -3.346478,-0.8466 -6.228839,-2.37074 -9.161939,-4.08314 L 23.663583,106.1716 1.0766223,67.813417 Z"
                        />

                    </clipPath>
                </defs>

            </svg>
        </div>
    );
};

export default Deemby;

const IconHexa = ({
                      sistema_activo, sistema_url, sistema_id, sistema_logo,
                  }) => {

    const token = localStorage.getItem('token');
    const url = "https://novomediaapp.azurewebsites.net/assents/deemby-sistemas/";
    const onClick = () => {
        if (sistema_activo && sistema_url) {
            window.location.href = sistema_url + '?sessionToken=' + token;
        }
    }
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
        }}>
            <div
                onClick={onClick}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "scale(1.15)",
                    cursor: "pointer",
                    marginTop: 10,
                    width: 120,
                    height: 120,
                    clipPath: "url(#clip-path)",
                    background: sistema_activo ? "#5d7cf9" : "#A2B6FD"
                }}>

                {sistema_logo && (
                    <img
                        style={{
                            zIndex: 100,
                            cursor: "pointer",
                            width: "65%", height: "65%"
                        }}
                        src={`${url}${sistema_id}.png`}
                        onClick={onClick}
                    />
                )}
            </div>
        </div>
    );
};
