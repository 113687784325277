import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

const ModalInfo = ({ id, isOpen, onClose }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id,type:'GetInfo'}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setData(data.data[0]);
        console.log(data.data[0]);
      } else {
        console.error('Error al obtener las secciones:', data.message);
      }
    })
    .catch(error => console.error('Error de red:', error));
  }, [id, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Datos"
      style={{
        content: {
          width: '60%',
          height:'50%',
          left: '20%',
          right: '20%',
          top: '20%',
        },
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FaTimes style={{ cursor: 'pointer' }} onClick={onClose} />
      </div>
      {data ? (
        <div>
          {data.tipo==='1' ?
            <img src={'https://novomediaapp.azurewebsites.net/assents/informativo/'+data.text} alt={data.text} style={{width:'100%'}} /> :
            <h4>{data.text}</h4>
          }
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </Modal>
  );
};

export default ModalInfo;
