import React, { useState } from 'react';
import Deemby from './imagenes/deembyDes.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 
import { useParams } from 'react-router-dom';

const Button = styled.button`
background-color: #007bff;
color: #fff;
border: none;
border-radius: 4px;
padding: 10px 20px;
cursor: pointer;
`;
const Input = styled.input`
  flex: 2;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
`;

const Logo = styled.img`
  width: 30%; /* Ajusta el tamaño según sea necesario */
  height: auto; /* Para mantener la proporción */
`; 

const ValidateCode = ({setUserPass}) => {
  const navigate = useNavigate(); 
  const [code, setCode] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const { IdUser } = useParams();

  const handleChange = (e, index) => {
    const newCode = e.target.value.toUpperCase(); // Convertir a mayúscula
    if (/^[a-zA-Z0-9]*$/.test(newCode) && newCode.length <= 1) {
      const newCodeArr = [...code];
      newCodeArr[index] = newCode;
      setCode(newCodeArr);
      setError('');
      // Mover el foco al siguiente campo
      if (index < 3) {
        const nextInput = document.getElementById(`char-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const enteredCode = code.join('');
    if (enteredCode.length !== 4) {
      setError('Completa el código');
      return;
    }else{
      fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({enteredCode,IdUser,type:'ValideCodePass'})
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            navigate('/FormPass');
            setUserPass(IdUser);
          } else {
            console.error('Error al obtener las publicaciones:', data.message);
          }
        })
        .catch(error => console.error('Error de red:', error));
    }
    
  };

  return (
    <div style={{ textAlign: 'center',padding:'20%'}}>
      <Logo src={Deemby} alt="Logo" />
      <h1>Validar Código</h1>
      <h3>Te hemos enviado un codigo a tu telefono registrado, introducelo para validar tu cuenta</h3>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {code.map((char, index) => (
            <Input
              key={index}
              id={`char-${index}`}
              type="text"
              value={char}
              maxLength={1}
              onChange={(e) => handleChange(e, index)}
              style={{ margin: '4px', padding: '20px', width: '10%', textAlign: 'center', fontSize: '20px',borderColor: '#5c7cfa' }}
            />
          ))}
        </div>
        <br />
        <Button type="submit" style={{ margin: '10px', padding: '20px' }}>Validar</Button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ValidateCode;
