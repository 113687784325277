import React from 'react';
import logo from './imagenes/logoDeemby.png';

const TopBar = () => {
  return (
    <div style={styles.topBar}>
      <div style={styles.logoContainer}>
        <img src={logo} alt="InfoGobierno" style={styles.logo} />
      </div>
    </div>
  );
};

const styles = {
  topBar: {
    position: 'fixed',
    top: 5,
    left: 5,
    width: '100%',
    height: '80px', // Ajusta la altura según sea necesario
    zIndex: 999, // Asegura que la barra superior esté en la parte superior del contenido
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    flex: 1,
  },
  logo: {
    height: '80px', // Ajusta el tamaño del logo según sea necesario
    width: 'auto',
  }
};

export default TopBar;
