import React, { useEffect, useState } from 'react';
import logo from './imagenes/deembyDes.png';
import { FaBell, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const TopBarUser = ({isDesktop}) => {
  const navigate = useNavigate();
  const id_user = localStorage.getItem('id_user');
  const [numNotificaciones, setNumNotificaciones] = useState(0);

  const gogOut = () => {
    const token = localStorage.getItem('token');

    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, type: 'goOut' }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          localStorage.setItem('token', null);
          localStorage.setItem('id_user', null);
          navigate('/');
        } else {
          console.error('Error al obtener las publicaciones:', data.message);
        }
      })
      .catch(error => console.error('Error de red:', error));
  };

  const getNotification = async () => {
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_user, type: 'GetNotification' }),
      });
      if (!response.ok) {
        throw new Error('Error en la solicitud.');
      }

      const data = await response.json();
      if (data.success) {
        setNumNotificaciones(data.data.length);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div style={styles.topBar}>
      <div style={styles.logoContainer} onClick={() => navigate('/')}>
        <img src={logo} alt="Deemby" style={styles.logo} />
      </div>
      <div style={{...styles.logoRightContainer,width:isDesktop?'5%':'10%'}} onClick={() => navigate('/Notificationes')}>
        {numNotificaciones > 0 && (
          <div style={styles.notificationCircle}>{numNotificaciones}</div>
        )}
        <FaBell style={{...styles.logoRight,width:isDesktop?'60%':'80%'}} />
      </div>
      <div style={{...styles.logoRightContainer,width:isDesktop?'5%':'10%'}} onClick={() => navigate('/FormExtraData')}>
        <FaCog style={{...styles.logoRight,width:isDesktop?'60%':'80%'}} />
      </div>
      <div style={{...styles.logoRightContainer,width:isDesktop?'5%':'10%'}} onClick={gogOut}>
        <FaSignOutAlt style={{...styles.logoRight,width:isDesktop?'60%':'80%'}} />
      </div>
    </div>
  );
};

const styles = {
  topBar: {
    position: 'absolute',
    top: '3%',
    left: '3%',
    width: 'calc(100% - 10%)',
    height: '80px',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  logoContainer: {
    flex: 1,
    cursor: 'pointer',
  },
  logo: {
    height: '80px',
    width: 'auto',
  },
  logoRightContainer: {
    position: 'relative',
    width: '10%',
    cursor: 'pointer',
  },
  logoRight: {
    height: 'auto',
    marginTop:'15%',
    color: '#5D7CF9',
  },
  notificationCircle: {
    position: 'absolute',
    top: '-9%',
    left: '-3%',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    width: '50%',
    height: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
  },
};

export default TopBarUser;
